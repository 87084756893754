import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import { AuthContext } from './AuthContext';
import { getSessionToken, getUserType } from '../utils/Auth';
import styles from '../styles/Navbar.module.css';
import logo from "../assets/logo.png";

function Navbar() {
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [userType, setUserType] = useState(null);

    
    // On page load, check if the user is logged in
    useEffect(() => {
        getSessionToken() ? setIsLoggedIn(true) : setIsLoggedIn(false);
        const dummy = localStorage.getItem('dummyRegister');
        console.log('dummy', dummy);
        if (dummy === 'true') {
            setIsLoggedIn(false);
        }
        isLoggedIn ? getUserType().then(type => setUserType(type)) : setUserType(null);
        console.log('isLoggedIn', isLoggedIn);
    }, [isLoggedIn, setIsLoggedIn]);


    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => { 
        setIsMenuOpen(false);
    }

    const handleNavigation = (link) => {
        navigate(link);
        closeMenu();
    };

    return (
        <nav className={styles["navbar"]}>
            <img src={logo} alt="Logo" className={styles["logo"]} onClick={() => handleNavigation('/')} />
            <div className={`${styles["menu-icon"]} ${isMenuOpen ? styles['open'] : ''}`} onClick={toggleMenu}>
                <div className={styles["burger"]}></div>
            </div>
            <ul className={`${styles["nav-links"]} ${isMenuOpen ? styles['active'] : ''}`}>
                <li><Button text="Accueil" link="/" variant='outline' onClick={() => handleNavigation('/')}/></li>
                <li><Button text="Explorer les offres" link="/jobs" variant='outline' onClick={() => handleNavigation('/jobs')}/></li>
                {isLoggedIn ? (
                    (userType === 'admin' || userType === 'company user') ? (
                        <li><Button text="Administration" link="/admin" variant='outline' onClick={() => handleNavigation('/admin')}/></li>
                    ) : (
                        null
                    )
                ) : (
                    <li><Button text="Recruter sur Wanteed ?" link="/company" variant='outline' onClick={() => handleNavigation('/company')}/></li>
                )}

                {isLoggedIn && <li><Button text="Mon Profil" link="/profile" variant="secondary" onClick={() => handleNavigation('/profile')}/></li>}
                {isLoggedIn ? (
                    <li>
                        {/* <Button text="Déconnexion" onClick={() => { handleLogout(); handleNavigation('/'); }} variant='inverted'/> */}
                    </li>
                ) : (
                    <li><Button text="Connexion" link="/login" variant='inverted' onClick={() => handleNavigation('/login')}/></li>
                )}
            </ul>
        </nav>
    );
}

export default Navbar;
